import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { AuthenticationService } from "../../../core/services/auth.service";
import { AuthfakeauthenticationService } from "../../../core/services/authfake.service";

import { ActivatedRoute, Router } from "@angular/router";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
  selector: "app-login2",
  templateUrl: "./login2.component.html",
  styleUrls: ["./login2.component.scss"],
})
/**
 * Login-2 component
 */
export class Login2Component implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService
  ) {}
  loginForm: FormGroup;
  submitted = false;
  error = "";
  returnUrl: string;
  type: string = "password";
  show: boolean = false;

  // set the currenr year
  year: number = new Date().getFullYear();

  ngOnInit(): void {
    document.body.classList.add("auth-body-bg");
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required]],
    });

    // reset login status
    // this.authenticationService.logout();
    // get return url from route parameters or default to '/'
    // tslint:disable-next-line: no-string-literal
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
  }

  carouselOption: OwlOptions = {
    items: 1,
    loop: false,
    margin: 0,
    nav: false,
    dots: true,
    responsive: {
      680: {
        items: 1,
      },
    },
  };

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  /**
   * Form submit
   */
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    } else {
      this.authenticationService
        .signIn({ email: this.f.email.value, password: this.f.password.value })
        .subscribe(
          (res: any) => {
            localStorage.setItem("token", res.data.attributes["access-token"]);
            this.authenticationService.getSelf().subscribe((data: any) => {
              localStorage.setItem(
                "userId",
                JSON.stringify(data.data.attributes)
              );
              localStorage.setItem(
                "user",
                JSON.stringify(data.data.attributes)
              );
              this.router.navigate(["/dashboards"]);
            });
          },
          (error) => {
            console.log(error);
            this.error = error ? error : "";
          }
        );
    }
  }

  onShow() {
    this.show = !this.show;
    if (!this.show) {
      this.type = "password";
    } else {
      this.type = "text";
    }
  }
}
