import {  Component, ElementRef, EventEmitter, Input,  OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'stairway-textinput',
  templateUrl: './textinput.component.html',
  styleUrls: ['./textinput.component.scss']
})
export class TextInputComponent implements OnInit {
  @Input() inputNote:string;
  @Input() inputId:string;
  @Output() noteChanged = new EventEmitter<{note:string, id:number}>();
  // bread crumb items
  @ViewChild("tref", {read: ElementRef}) tref: ElementRef;
 


  note:string
  id:number
  ref: HTMLDivElement
  constructor() {
    }


  ngOnInit() {
    this.note = this.inputNote ?? ""
    this.id = parseInt(this.inputId) 
  }
  ngAfterViewInit(){
    this.ref = this.tref.nativeElement
    this.ref.innerText = this.note
    CheckEmpty(this.ref.innerText, this.ref)
  }

  onNoteInput(e:InputEvent){
    const el = e.target as HTMLDivElement
    const innerText = el.innerText 
    CheckEmpty(innerText, el)
  }

  onNoteChange(e:InputEvent, id?:number){
    const note = (e.target as HTMLDivElement).innerText
    if(this.note === note) return;
    this.noteChanged.emit({note:note, id:this.id})
  }
}

const CheckEmpty = (text:string, el:HTMLDivElement) => {
    if(text === "") el.className="note-placeholder"
    else el.className=""
}