import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

// const environment.base_url = 'https://api.stairwaygames.com/api/v1'

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  constructor(private http: HttpClient) {}

  // API AUTH
  signIn(body: { email: string; password: any }) {
    return this.http.post(`${environment.base_url}/auth/sign-in`, body);
  }

  signUp(body: any) {
    return this.http.post(`${environment.base_url}/auth/sign-up`, body);
  }

  getSelf() {
    return this.http.get(`${environment.base_url}/auth/token`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }

  getDetail() {
    const id = localStorage.getItem("id");
    return this.http.get(`${environment.base_url}/users/${id}`);
  }

  refreshToken(body: any) {
    return this.http.post(`${environment.base_url}/auth/refresh-token`, body);
  }

  verify(token: any) {
    return this.http.get(`${environment.base_url}/auth/refresh-token${token}`);
  }

  sync() {
    return this.http.get(`${environment.base_url}/dynamo`);
  }

  logout(token: any) {
    return this.http.delete(`${environment.base_url}/auth/logout/${token}`);
  }

  currentUser() {
    return localStorage.getItem("token");
  }

  logoutAll() {
    return this.http.delete(`${environment.base_url}/auth/logout-all`);
  }

  token() {
    return this.http.get(`${environment.base_url}/auth/token`);
  }
}
