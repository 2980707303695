<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <img
              src="https://stairway-fe-front.vercel.app/icons/Stairway_games_black.png"
              alt=""
              height="50"
            />
          </span>
          <span class="logo-lg">
            <img
              src="https://stairway-fe-front.vercel.app/icons/Stairway_games_black.png"
              alt=""
              height="17"
            />
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img
              src="https://stairway-fe-front.vercel.app/icons/Stairway_games_white.png"
              alt=""
              height="50"
            />
          </span>
          <span class="logo-lg">
            <img
              src="https://stairway-fe-front.vercel.app/icons/Stairway_games_white.png"
              alt=""
              height="50"
            />
          </span>
        </a>
      </div>
    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block d-lg-none ms-2" ngbDropdown>
        <button
          type="button"
          class="btn header-item noti-icon"
          id="page-header-search-dropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          ngbDropdownToggle
          aria-expanded="false"
        >
          <i class="mdi mdi-magnify"></i>
        </button>
      </div>

      <button type="button" class="btn btn-light btn-label waves-light">
        <i class="bx bx-hourglass label-icon" (click)="sync()"></i>
      </button>
      <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item"
          ngbDropdownToggle
          id="page-header-user-dropdown"
        >
          <img
            class="rounded-circle header-profile-user"
            src="https://newsite.stairwaygames.com/icons/Stairway_games_black.png"
            alt="Header Avatar"
          />
          <span class="d-none d-xl-inline-block ms-1">{{
            dataUser?.name
          }}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->

          <a
            class="dropdown-item text-danger"
            href="javascript: void(0);"
            (click)="logout()"
            ><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
            ></i>
            {{ "HEADER.LOGIN.LOGOUT" | translate }}</a
          >
        </div>
      </div>
    </div>
  </div>
</header>
