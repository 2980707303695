<div class="container-fluid">

  <!-- start page title -->
  <app-page-title title="Form Repeater" [breadcrumbItems]="breadCrumbItems"></app-page-title>
  <!-- end page title -->

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Example</h4>
          <form class="repeater" [formGroup]="form">
            <div formArrayName="formlist">
              <div *ngFor="let data1 of formData().controls; let i=index;">
                <div [formGroupName]="i" class="row">
                  <div class="mb-3 col-lg-2">
                    <label for="name">Name</label>
                    <input type="text" formControlName="name" class="form-control">
                  </div>

                  <div class="mb-3 col-lg-2">
                    <label for="email">Email</label>
                    <input type="email" formControlName="email" class="form-control">
                  </div>

                  <div class="mb-3 col-lg-2">
                    <label for="subject">Subject</label>
                    <input type="text" formControlName="subject" class="form-control">
                  </div>

                  <div class="mb-3 col-lg-2">
                    <label for="resume">Resume</label>
                    <input type="file" class="form-control-file" formControlName="file" id="resume">
                  </div>

                  <div class="mb-3 col-lg-2">
                    <label for="message">Message</label>
                    <textarea class="form-control" formControlName="msg"></textarea>
                  </div>

                  <div class="col-lg-2 align-self-center">
                    <div class="d-grid">
                      <input type="button" class="btn btn-primary" value="Delete" (click)="removeField(i)">
                    </div>
                  </div>
                </div>
              </div>
              <input type="button" class="btn btn-success mt-3 mt-lg-0" value="Add" (click)="addField()">
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Nested</h4>
          <form class="outer-repeater">
            <div class="outer">
              <div>
                <div class="mb-3">
                  <label for="formname">Name :</label>
                  <input type="text" class="form-control" id="formname" placeholder="Enter your Name...">
                </div>

                <div class="mb-3">
                  <label for="formemail">Email :</label>
                  <input type="email" class="form-control" id="formemail" placeholder="Enter your Email...">
                </div>

                <div class="mb-4">
                  <div class="mb-3" [formGroup]="phoneData">
                    <div formArrayName="phoneValue">
                      <label>Phone no :</label>
                      <div class="inner mb-3 row" *ngFor="let data1 of  phonedata().controls; let i=index;"
                        [formGroupName]="i">
                        <div class="col-md-10 col-8">
                          <input type="text" class="inner form-control" formControlName="phonenumber"
                            placeholder="Enter your phone no...">
                        </div>
                        <div class="col-md-2 col-4">
                          <div class="d-grid">
                            <input type="button" class="btn btn-primary inner" value="Delete" (click)="deletePhone(i)">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <input type="button" class="btn btn-success inner" value="Add Number" (click)="addPhone()">
                </div>

                <div class="mb-3">
                  <label class="d-block mb-3">Gender :</label>

                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                      value="option1">
                    <label class="form-check-label" for="inlineRadio1">Male</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                      value="option2">
                    <label class="form-check-label" for="inlineRadio2">Female</label>
                  </div>
                </div>

                <div class="mb-3">
                  <label for="formmessage">Message :</label>
                  <textarea id="formmessage" class="form-control" rows="3"></textarea>
                </div>
                <button type="submit" class="btn btn-primary">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>